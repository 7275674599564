import React, {
    useState,
    useEffect,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import '../../Assets/CSS/Project/Project.css';
import { assignProjectManager } from '../../apis/ProjectApi';
import {
    getDeveloper,
    setProjectsData,
} from '../../Redux/Slices/Project/ProjectsSlice';

import {
    DataGrid,
    GridMoreVertIcon,
} from '@mui/x-data-grid';
import CreateProject from './Components/CreateProject';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    getProjectRemark,
    getProjects,
    removeDeveloper,
    setFilterParameters,
} from '../../Redux/Slices/Project/ProjectsSlice';
import EditProject from './Components/EditProject';
import OnboardDialog from './Components/OnboardDialog';
import { useFormik } from 'formik';
import moment from 'moment';
import {
    getAllProjectManagers,
    getTotalDevelopers,
} from '../../Redux/Slices/Lists/ListsSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@mui/styles';
import LoadingSpinner from '../../utils/LoadingSpinner';
import DeveloperModal from '../../utils/DeveloperModal';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { FilterOutlined } from '@ant-design/icons';
import ActionDialog from './Components/ActionDialog';
import FilterDialog from './Components/FilterDialog';
import dayjs from 'dayjs';
import RemarkSectionModal from './Components/RemarkSectionModal';
import StatusChangeLogModal from './Components/StatusChangeLogModal';
import FutureOnboardingsModal from './Components/FutureOnboardingsModal';

const AssignPmDialog = ({
    open,
    onClose,
    projectId,
    isAssign,
    unAssignPMs,
}) => {
    const { developers, projectManagers } =
        useSelector((state) => state?.lists);

    const formik = useFormik({
        initialValues: {
            projectId: '',
            fullTimeUserId: [],
            fullTimebillingType: 'Full Time',
            onBoardingDate: '05-23-2023',
        },
        onSubmit: (values) => {
            if (isAssign) {
                if (
                    values.fullTimeUserId
                        .length === 0
                ) {
                    toast(
                        'Please select project manager',
                    );
                } else {
                    assignProjectManager(values)
                        .then((res) => {
                            toast(
                                'Project Manager Assigned sucessfully',
                            );
                            dispatch(
                                getProjects(
                                    'Active',
                                ),
                            );
                            onClose();
                        })
                        .catch((err) =>
                            toast(
                                err?.response
                                    ?.data
                                    ?.message,
                            ),
                        );
                }
            } else {
                if (
                    values.fullTimeUserId
                        .length === 0
                ) {
                    toast(
                        'Please select project manager',
                    );
                } else {
                    dispatch(
                        removeDeveloper(values),
                    )
                        .then((res) => {
                            toast(
                                'Project Manager unassigned sucessfully',
                            );
                            dispatch(
                                getProjects(
                                    'Active',
                                ),
                            );
                            onClose();
                        })
                        .catch((err) =>
                            toast(
                                err?.response
                                    ?.data
                                    ?.message,
                            ),
                        );
                }
            }
        },
    });

    const dispatch = useDispatch();

    useEffect(() => {
        formik.setFieldValue(
            'projectId',
            projectId,
        );
    }, [projectId]);

    const projectManagerId = useSelector(
        (store) => {
            return store?.designation?.designations?.filter(
                (item) => item?.name === 'PM',
            )[0]?._id;
        },
    );

    useEffect(() => {
        dispatch(
            getAllProjectManagers({
                projectManagerId,
                role: 'PM',
            }),
        );
        dispatch(getTotalDevelopers());
    }, [projectManagerId]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Dialog
                    open={open}
                    onClose={onClose}
                >
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '300px',
                                justifyContent:
                                    'space-between',
                            }}
                        >
                            <Typography>
                                {isAssign
                                    ? 'Assign project manager'
                                    : 'Unassign project manager'}
                            </Typography>
                            <CloseIcon
                                sx={{
                                    cursor: 'pointer',
                                }}
                                onClick={onClose}
                            />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            mt={1}
                            mb={2}
                        >
                            <Autocomplete
                                options={
                                    isAssign
                                        ? projectManagers ||
                                          []
                                        : unAssignPMs
                                }
                                getOptionLabel={(
                                    option,
                                ) =>
                                    `${option?.fullName}`
                                }
                                onChange={(
                                    event,
                                    value,
                                ) => {
                                    const fullTimeUserId =
                                        value
                                            ? value._id
                                            : '';
                                    formik.setFieldValue(
                                        'fullTimeUserId',
                                        [
                                            fullTimeUserId,
                                            ...formik.values.fullTimeUserId.slice(
                                                1,
                                            ),
                                        ],
                                    );
                                }}
                                renderInput={(
                                    params,
                                ) => (
                                    <TextField
                                        {...params}
                                        required
                                        sx={{
                                            minWidth:
                                                '200px  !important',
                                        }}
                                        value={
                                            developers?.name
                                        }
                                        name="projectManager"
                                        label="Project Managers"
                                    />
                                )}
                            />
                        </Box>

                        <Box
                            sx={{
                                marginTop:
                                    '2rem !important',
                                display: 'flex',
                                justifyContent:
                                    'end',
                            }}
                        >
                            <Button
                                onClick={
                                    formik.handleSubmit
                                }
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </form>
        </>
    );
};

const useStyles = makeStyles({
    filterField: {
        backgroundColor: 'white',
        width: '200px',
    },
    customScrollbar: {
        'scrollbarWidth': 'thin', // For Firefox
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    actionColumn: {
        // position: "sticky",
        left: '0', // Adjust the distance from the left as needed
        zIndex: 10,
        backgroundColor: 'white',
        boxShadow:
            '0px 2px 4px rgba(0, 0, 0, 0.8)',
    },

    actionHeader: {
        position: 'sticky !important',
        left: '0', // Adjust the distance from the left as needed
        zIndex: 10,
        backgroundColor: '#FAFAFA',
        color: 'black',
        boxShadow:
            '0px 2px 4px rgba(0, 0, 0, 0.8)',
    },
});

const Projects = () => {
    const {
        projects,
        isLoading,
        projectRemarks,
        developerData,
        isLoading1,
    } = useSelector((state) => state?.project);

    const [pageSize, setPageSize] = useState(10);
    const status = 'Active';
    const [status1, setStatus1] = useState();

    const classes = useStyles();

    const dispatch = useDispatch();
    const [
        openCreateProject,
        setOpenCreateProject,
    ] = useState(false);
    const [openEditProject, setOpenEditProject] =
        useState(false);
    const [
        openOnboardProjectDialog,
        setOpenOnboardProjectDialog,
    ] = useState(false);
    const [
        openDeveloperModal,
        setOpenDeveloperModal,
    ] = useState(false);
    const [openActionModal, setOpenActionModal] =
        useState(false);
    const [
        openFilterDialog,
        setOpenFilterDialog,
    ] = useState(false);
    const [projectName, setProjectName] =
        useState('');
    const [projectId, setProjectId] =
        useState('');
    const [
        projectStartDate,
        setProjectStartDate,
    ] = useState('');
    const [project, setProject] = useState();
    const [pms, setPms] = useState([]);
    const [isDeveloper, setIsDeveloper] =
        useState(null);
    const [isAssignPm, setIsAssignPm] =
        useState(false);
    const [pmToUnassign, setPmToUnassign] =
        useState([]);
    const [remarkModal, setRemarkModal] =
        useState(false);
    const [
        openStatusLogModal,
        setOpenStatusLogModal,
    ] = useState(false);
    const [
        openFutureOnboardingsModal,
        setOpenFutureuOnboardingsModal,
    ] = useState(false);
    const searchFilters = useSelector(
        (state) => state?.project.searchFilters,
    );
    const role = localStorage.getItem(
        'designation',
    );
    const { projectsData } = useSelector(
        (state) => state?.project,
    );

    useEffect(() => {
        if (status !== '') {
            dispatch(getProjects(status));
        }
    }, [status]);

    const handleResetClick = () => {
        setProjectName('');
        dispatch(setFilterParameters({}));

        dispatch(setProjectsData(''));
        dispatch(getProjects());
    };

    const findDeveloper = async (id) => {
        try {
            await dispatch(getDeveloper(id));
        } catch (error) {
            toast(error?.message);
        }
    };

    const editHandler = async () => {
        setOpenEditProject(true);
    };

    const assignPMHandler = async (e) => {
        setIsDeveloper(false);
        setIsAssignPm(true);
        setOpenOnboardProjectDialog(true);
    };

    const onBoardHandler = async (e) => {
        setIsDeveloper(true);
        setOpenOnboardProjectDialog(true);
    };

    const unassignPMHandler = async (e) => {
        setIsDeveloper(false);
        setIsAssignPm(false);
        setOpenOnboardProjectDialog(true);
    };

    const remarkHandler = () => {
        setRemarkModal(true);
    };

    const statusChangeLogHandler = () => {
        setOpenStatusLogModal(true);
    };

    const futureOnboardingsHandler = () => {
        setOpenFutureuOnboardingsModal(true);
    };

    const columns = [
        {
            field: 'projectName',
            headerName: 'Project name',
            width: 150,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                const myProps = {
                    id: params.row.key, // Replace with the actual field name for project ID
                    name: params.value,
                };
                return (
                    <Tooltip
                        title={
                            params.row.description
                        }
                        placement="right"
                        arrow
                    >
                        <span
                            // className={styles.tooltip}
                            style={{
                                fontSize: '13px',
                                cursor: 'pointer',
                                fontWeight:
                                    'bold',
                            }}
                        >
                            <Link
                                to={`/dashboard/projects/${myProps.name}`} // Adjust the route as needed
                                style={{
                                    color: 'black',
                                }}
                                state={myProps}
                            >
                                {params.value}
                            </Link>
                        </span>
                    </Tooltip>
                );
            },
        },

        {
            field: 'projectManager',
            headerName: 'Project manager',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                const projectManagers =
                    params?.formattedValue
                        ?.length === 0
                        ? 'N/A'
                        : params?.formattedValue
                              ?.map((item) =>
                                  item?.lastName
                                      ? item?.fullName
                                      : item?.fullName,
                              )
                              .join(', ');
                return (
                    <Tooltip
                        title={projectManagers}
                        placement="right"
                        arrow
                    >
                        <span
                            style={{
                                textOverflow:
                                    'ellipsis',
                                whiteSpace:
                                    'nowrap',
                                overflow:
                                    'hidden',
                                cursor: 'context-menu',
                            }}
                        >
                            {projectManagers}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'developers',
            headerName: 'Developers',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return params?.formattedValue
                    ?.length ? (
                    <div>
                        <Button
                            onClick={() => {
                                setProjectId(
                                    params?.row
                                        ?.key,
                                );
                                findDeveloper(
                                    params?.row
                                        ?.key,
                                );
                                setPms(
                                    params.row
                                        .projectManager,
                                );
                                setOpenDeveloperModal(
                                    true,
                                );
                            }}
                            style={{
                                textDecoration:
                                    'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {
                                params
                                    ?.formattedValue
                                    ?.length
                            }
                        </Button>
                    </div>
                ) : (
                    <Typography
                        component="p"
                        sx={{
                            cursor: 'context-menu',
                        }}
                    >
                        {
                            params?.formattedValue
                                ?.length
                        }
                    </Typography>
                );
            },
        },
        {
            field: 'clientName',
            headerName: 'Client name',
            width: 200,
            headerAlign: 'left',
            align: 'left',

            renderCell: (params) => {
                const clientName =
                    params?.row?.clientName === ''
                        ? 'N/A'
                        : params?.row?.clientName;

                return (
                    <Tooltip
                        title={clientName}
                        placement="right"
                        arrow
                    >
                        <span
                            style={{
                                textOverflow:
                                    'ellipsis',
                                whiteSpace:
                                    'nowrap',
                                overflow:
                                    'hidden',
                                cursor: 'context-menu',
                            }}
                        >
                            {clientName}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,

            renderCell: (params) => {
                const cellValue = params.value;
                // Define a style object for the cell based on the condition

                const cellStyle =
                    cellValue === 'Active'
                        ? {
                              padding:
                                  '4px 12px 4px 12px',
                              backgroundColor:
                                  '#178C451A',
                              color: '#178C45',
                              borderRadius:
                                  '13px',
                              font: 'Lato',
                              fontWeight: '500',
                              fontSize: '16px',
                              width: '98px',
                              display: 'flex',
                              justifyContent:
                                  'center',
                          }
                        : {
                              padding:
                                  '4px 12px 4px 12px',
                              backgroundColor:
                                  '#F0F0F0',
                              color: '#5C5C5C',
                              borderRadius:
                                  '16px',
                              font: 'Lato',
                              fontWeight: '500',
                              fontSize: '16px',
                              width: '98px',
                              display: 'flex',
                              justifyContent:
                                  'center',
                          };
                return (
                    <div style={cellStyle}>
                        {cellValue}
                    </div>
                );
            },
        },
        {
            field: 'modifiedStatusDate',
            headerName: 'Status Change Date',
            width: 200,
            headerAlign: 'center',
            align: 'center',

            renderCell: (params) => {
                return (
                    <span
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                        }}
                    >
                        {params.value}
                    </span>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            headerClassName: classes.actionHeader,
            cellClassName: classes.actionColumn,

            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip
                            title="Perform Action"
                            placement="top"
                        >
                            <IconButton
                                onClick={() => {
                                    setProjectId(
                                        params
                                            ?.row
                                            ?.key,
                                    );
                                    setOpenActionModal(
                                        true,
                                    );
                                    setPmToUnassign(
                                        params?.formattedValue,
                                    );
                                    setStatus1(
                                        params
                                            ?.row
                                            ?.status,
                                    );
                                    setProjectStartDate(
                                        params
                                            ?.row
                                            ?.startDate,
                                    );
                                    dispatch(
                                        getProjectRemark(
                                            {
                                                projectId:
                                                    params
                                                        ?.row
                                                        ?.key,
                                            },
                                        ),
                                    );
                                }}
                                size="small"
                                sx={{
                                    marginLeft:
                                        'auto',
                                }}
                            >
                                <GridMoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            field: 'techStack',
            headerName: 'Tech stack',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                const techstackName =
                    params?.formattedValue
                        ?.length === 0
                        ? 'N/A'
                        : params?.formattedValue
                              ?.map(
                                  (item) =>
                                      item?.name,
                              )
                              .join(',');

                return (
                    <Tooltip
                        title={techstackName}
                        placement="right"
                        arrow
                    >
                        <span
                            style={{
                                textOverflow:
                                    'ellipsis',
                                whiteSpace:
                                    'nowrap',
                                overflow:
                                    'hidden',
                                cursor: 'context-menu',
                            }}
                        >
                            {techstackName}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'group',
            headerName: 'Group Name',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'typeOfProject',
            headerName: 'Project type',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        // column for invoice
        {
            field: 'invoice',
            headerName: 'Invoice',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },

        {
            field: 'startDate',
            headerName: 'Start date',
            width: 200,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                const startDate =
                    params.value === ''
                        ? 'N/A'
                        : params.value;

                return (
                    <Tooltip
                        title={params.value}
                        placement="right"
                        arrow
                    >
                        <span
                            style={{
                                textOverflow:
                                    'ellipsis',
                                whiteSpace:
                                    'nowrap',
                                overflow:
                                    'hidden',
                                cursor: 'context-menu',
                            }}
                        >
                            {moment(
                                startDate,
                            ).format('L')}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'estimatedEndDate',
            headerName: 'Estimated end date',
            width: 200,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                const estimatedEndDate =
                    params.value === ''
                        ? 'N/A'
                        : params.value;
                return (
                    <Tooltip
                        title={params.value}
                        placement="right"
                        arrow
                    >
                        <span
                            style={{
                                textOverflow:
                                    'ellipsis',
                                whiteSpace:
                                    'nowrap',
                                overflow:
                                    'hidden',
                                cursor: 'context-menu',
                            }}
                        >
                            {moment(
                                estimatedEndDate,
                            ).format('L')}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
    ];

    const eventColumns = [
        {
            field: 'projectName',
            headerName: 'Project Name',
            width: 150,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                const myProps = {
                    id: params.row.key, // Replace with the actual field name for project ID
                    name: params.value,
                };

                return (
                    <Tooltip
                        title={params.value}
                        placement="right"
                        arrow
                    >
                        <span
                            // className={styles.tooltip}
                            style={{
                                fontSize: '13px',
                                cursor: 'pointer',
                                fontWeight:
                                    'bold',
                            }}
                        >
                            <Link
                                to={`/dashboard/projects/${myProps.name}`} // Adjust the route as needed
                                style={{
                                    color: 'black',
                                }}
                                state={myProps}
                            >
                                {params.value}
                            </Link>
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'eventDescription',
            headerName: 'Event Description',
            width: 600,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                const events =
                    params?.row?.eventDescription
                        ?.events;

                return (
                    <div
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                            paddingLeft: '0.2rem',
                        }}
                    >
                        <ol
                            style={{
                                listStyleType:
                                    'decimal',
                            }}
                        >
                            {events?.map(
                                (
                                    event,
                                    index,
                                ) => (
                                    <li
                                        key={
                                            index
                                        }
                                        style={{
                                            marginLeft:
                                                '1em',
                                        }}
                                    >
                                        {event?.eventValue ||
                                            'NA'}
                                    </li>
                                ),
                            )}
                        </ol>
                    </div>
                );
            },
        },
        {
            field: 'eventDate',
            headerName: 'Estimated End Date',
            align: 'left',
            headerAlign: 'left',
            width: 150,
            renderCell: (params) => {
                const eventCreationDate =
                    params?.row?.eventDate?.events?.map(
                        (event) => ({
                            ...event,
                            estimatedEnddate:
                                dayjs(
                                    event?.estimatedEnddate,
                                ).format(
                                    'DD-MMM-YYYY',
                                ),
                        }),
                    );

                return (
                    <div
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                            paddingLeft: '0.2rem',
                        }}
                    >
                        <ol
                            style={{
                                listStyleType:
                                    'decimal',
                            }}
                        >
                            {eventCreationDate?.map(
                                (date, index) => (
                                    <li
                                        key={
                                            index
                                        }
                                        style={{
                                            marginLeft:
                                                '1em',
                                        }}
                                    >
                                        {date?.estimatedEnddate ||
                                            'NA'}
                                    </li>
                                ),
                            )}
                        </ol>
                    </div>
                );
            },
        },
        {
            field: 'eventLeftDays',
            headerName: 'Days Left',
            width: 150,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => {
                const eventDate =
                    params?.row?.eventDate
                        ?.events || [];
                const eventLeftDate =
                    eventDate?.map((item) => {
                        if (item?.isCompleted) {
                            return 'Completed';
                        }
                        const estimatedEndDate =
                            item?.estimatedEnddate ||
                            null;
                        if (estimatedEndDate) {
                            const timeDifference =
                                Math.ceil(
                                    (new Date(
                                        estimatedEndDate,
                                    ).getTime() -
                                        Date.now()) /
                                        (1000 *
                                            60 *
                                            60 *
                                            24),
                                );
                            return timeDifference;
                        }
                        return 'NA';
                    });

                return (
                    <div
                        style={{
                            textOverflow:
                                'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            cursor: 'context-menu',
                            paddingLeft: '0.2rem',
                        }}
                    >
                        <ol
                            style={{
                                listStyleType:
                                    'circle',
                            }}
                        >
                            {eventLeftDate?.map(
                                (date, index) => (
                                    <li
                                        key={
                                            index
                                        }
                                        style={{
                                            marginLeft:
                                                '1em',
                                        }}
                                    >
                                        {date}
                                    </li>
                                ),
                            )}
                        </ol>
                    </div>
                );
            },
        },
    ];

    const projectsWithEvents = projects.filter(
        (project) =>
            project.projects?.events?.length > 0,
    );

    const eventsRow = projectsWithEvents?.map(
        (item) => {
            function capitalizeFirstLetter(
                string,
            ) {
                return (
                    string
                        .charAt(0)
                        .toUpperCase() +
                    string.slice(1)
                );
            }

            const capitalizedResponse =
                capitalizeFirstLetter(
                    item?.projects?.projectName,
                );

            return {
                key: item.projects?._id,
                projectManager: item?.pm,
                projectName: capitalizedResponse,
                actions: item?.pm,
                eventDescription: item?.projects,
                eventDate: item?.projects,
                eventLeftDays: item?.projects,
            };
        },
    );

    const rows = projects?.map((item) => {
        function capitalizeFirstLetter(string) {
            return (
                string.charAt(0).toUpperCase() +
                string.slice(1)
            );
        }

        const capitalizedResponse =
            capitalizeFirstLetter(
                item?.projects?.projectName,
            );

        return {
            key: item.projects?._id,
            projectManager: item?.pm,
            projectName: capitalizedResponse,
            developers: item?.developers,
            arrayOfDevelopers: item?.developers,
            clientName:
                item?.projects?.clientName,
            status: item?.projects?.status,
            modifiedStatusDate: item?.projects
                ?.modifiedStatusDate
                ? dayjs(
                      item?.projects
                          ?.modifiedStatusDate,
                  ).format('DD-MM-YYYY')
                : 'NA',
            techStack: item?.projects?.techStack,
            group:
                item?.projects?.group
                    ?.groupName ?? 'NA',
            typeOfProject:
                item?.projects?.typeOfProject,
            // invoice
            invoice:
                item?.projects?.invoice || 'N/A',
            startDate: item?.projects?.startDate,
            estimatedEndDate:
                item?.projects?.estimatedEndDate,
            internalMeetingDaysAndTimings:
                item?.internalMeetingDaysAndTimings,
            clientMeetingDaysAndTimings:
                item?.clientMeetingDaysAndTimings,
            description: item?.projects
                ?.description
                ? item?.projects?.description
                : 'N/A',
            pmActionUnassign: item?.pm,
            actions: item?.pm,
            eventDescription: item?.projects,
            eventDate: item?.projects,
            eventLeftDays: item?.projects,
        };
    });

    const handleCloseAddProjectDialog = () => {
        setOpenCreateProject(false);
    };

    const handleCloseEditProjectDialog = () => {
        setOpenEditProject(false);
    };

    const handleCloseDeveloperModal = () => {
        setOpenDeveloperModal(false);
    };

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <>
            <Box
                sx={{
                    height: '85vh',
                    backgroundColor: 'white',
                    padding:
                        '10px 32px 24px 32px',
                    borderRadius: '16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent:
                            'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography
                            variant="h5"
                            sx={{
                                font: 'Montserrat',
                                fontSize: '32px',
                                fontWeight: '500',
                            }}
                        >
                            Project
                        </Typography>
                    </Box>
                    {role !== 'PM' &&
                        role !== 'BA' &&
                        role !== 'CSM' && (
                            <Box>
                                <Button
                                    variant="contained"
                                    sx={{
                                        'marginRight':
                                            '2px',
                                        'backgroundColor':
                                            '#4E79FA',
                                        'color':
                                            '#FFFFFF',
                                        '&:hover':
                                            {
                                                backgroundColor:
                                                    '#4E79FA',
                                            },
                                        'height':
                                            '56px',
                                    }}
                                    onClick={() =>
                                        setOpenCreateProject(
                                            true,
                                        )
                                    }
                                >
                                    <AddCircleOutlineOutlined
                                        sx={{
                                            marginRight:
                                                '2px',
                                        }}
                                    />
                                    Create New
                                    Project
                                </Button>
                            </Box>
                        )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent:
                            'space-between',
                        alignItems: 'center',
                        marginTop: '1rem',
                        marginBottom: '10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <TextField
                            size="small"
                            className={
                                classes.filterField
                            }
                            placeholder="Search Project Name"
                            value={projectName}
                            onChange={(e) =>
                                setProjectName(
                                    e.target
                                        .value,
                                )
                            }
                            autoComplete={'off'}
                            sx={{
                                width: '25rem',
                                borderRadius:
                                    '8px',
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="outlined"
                            endIcon={
                                <FilterOutlined />
                            }
                            onClick={() =>
                                setOpenFilterDialog(
                                    true,
                                )
                            }
                            sx={{
                                borderRadius:
                                    '8px',
                                padding:
                                    '16px, 24px, 16px, 24px',
                                width: '109px',
                                backgroundColor:
                                    Object.keys(
                                        searchFilters,
                                    ).length >
                                        0 ||
                                    !!projectsData
                                        ? '#4E79FA'
                                        : 'white',
                                color:
                                    Object.keys(
                                        searchFilters,
                                    ).length >
                                        0 ||
                                    !!projectsData
                                        ? 'white'
                                        : 'black',
                            }}
                        >
                            Filter
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={
                                handleResetClick
                            }
                            sx={{
                                borderRadius:
                                    '8px',

                                padding:
                                    '16px, 24px, 16px, 24px',
                                // color: "#C2C2C2",
                                width: '109px',
                            }}
                        >
                            Reset
                        </Button>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: '80%',
                    }}
                >
                    <DataGrid
                        rows={rows}
                        getRowId={(row) =>
                            row?.key
                        }
                        columns={columns}
                        pageSize={pageSize}
                        rowHeight={40}
                        pagination
                        onPageSizeChange={(
                            newPageSize,
                        ) => {
                            setPageSize(
                                newPageSize,
                            );
                        }}
                        // rowsPerPageOptions={[
                        //     10, 25, 50, 100,
                        // ]}
                        experimentalFeatures={{
                            newEditingApi: true,
                        }}
                        disableColumnMenu
                        disableColumnFilter
                        sx={{
                            '.MuiDataGrid-columnSeparator':
                                {
                                    display:
                                        'none',
                                },
                            '&.MuiDataGrid-root':
                                {
                                    border: 'none',
                                },
                            'backgroundColor':
                                'white',
                            '& .MuiDataGrid-columnHeaders':
                                {
                                    // fontSize: "1rem",
                                    background:
                                        '#FAFAFA',
                                    color: 'black',
                                },
                            '& .MuiDataGrid-sortIcon':
                                {
                                    color: 'white',
                                },
                            '& 	.MuiDataGrid-menuIconButton':
                                {
                                    color: 'white',
                                },
                            '& 	.MuiDataGrid-filterIcon':
                                {
                                    color: 'white',
                                },
                            '& .MuiDataGrid-window':
                                {
                                    // Customize the entire grid window (both horizontal and vertical scrollbars)
                                    // For example:
                                    'scrollbarWidth':
                                        'thin', // For Firefox
                                    '&::-webkit-scrollbar':
                                        {
                                            width: '10px', // Width of the scrollbar
                                        },
                                    '&::-webkit-scrollbar-track':
                                        {
                                            background:
                                                '#f1f1f1', // Background color of the scrollbar track
                                        },
                                    '&::-webkit-scrollbar-thumb':
                                        {
                                            background:
                                                '#888', // Color of the scrollbar thumb
                                            borderRadius:
                                                '5px', // Border radius of the scrollbar thumb
                                        },
                                    '&::-webkit-scrollbar-thumb:hover':
                                        {
                                            background:
                                                '#555', // Color of the scrollbar thumb on hover
                                        },
                                },
                            '& .MuiDataGrid-virtualScroller':
                                {
                                    // Customize the vertical scrollbar container
                                    // For example:
                                    background:
                                        'white', // Background color of the vertical scrollbar container
                                    width: '10px', // Width of the vertical scrollbar container
                                },
                            '& .MuiDataGrid-horizontalScroller':
                                {
                                    // Customize the horizontal scrollbar container
                                    // For example:
                                    background:
                                        '#f1f1f1', // Background color of the horizontal scrollbar container
                                    height: '10px', // Height of the horizontal scrollbar container
                                },
                            '& .MuiDataGrid-scrollbar':
                                {
                                    // Styles for both vertical and horizontal scrollbar thumbs
                                    // Customize the common styles for the scrollbar thumb
                                    // For example:
                                    background:
                                        '#888', // Color of the scrollbar thumb
                                    borderRadius:
                                        '5px', // Border radius of the scrollbar thumb
                                },
                            '& .MuiDataGrid-scrollbar-thumb':
                                {
                                    // Styles for scrollbar thumb itself
                                    // You can further customize the styles for the scrollbar thumb
                                    // For example:
                                    'background':
                                        '#888', // Color of the scrollbar thumb
                                    'borderRadius':
                                        '5px', // Border radius of the scrollbar thumb
                                    '&:hover': {
                                        background:
                                            'red', // Color of the scrollbar thumb on hover
                                    },
                                },
                        }}
                        className={
                            classes.customScrollbar
                        }
                        filterModel={{
                            items: [
                                {
                                    columnField:
                                        'projectName',
                                    operatorValue:
                                        'contains',
                                    value: `${projectName}`,
                                },
                            ],
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps:
                                    {
                                        debounceMs: 500,
                                    },
                            },
                        }}
                    />
                </Box>
                {openCreateProject && (
                    <CreateProject
                        open={openCreateProject}
                        onClose={
                            handleCloseAddProjectDialog
                        }
                    />
                )}
                {isDeveloper ? (
                    <OnboardDialog
                        open={
                            openOnboardProjectDialog
                        }
                        onClose={() =>
                            setOpenOnboardProjectDialog(
                                false,
                            )
                        }
                        projectId={projectId}
                        projectStartDate={
                            projectStartDate
                        }
                    />
                ) : (
                    <AssignPmDialog
                        open={
                            openOnboardProjectDialog
                        }
                        onClose={() =>
                            setOpenOnboardProjectDialog(
                                false,
                            )
                        }
                        projectId={projectId}
                        unAssignPMs={pmToUnassign}
                        isAssign={isAssignPm}
                    />
                )}
                {openEditProject && (
                    <EditProject
                        open={openEditProject}
                        id={projectId}
                        projectData={project}
                        onClose={
                            handleCloseEditProjectDialog
                        }
                    />
                )}
                {remarkModal && (
                    <RemarkSectionModal
                        open={remarkModal}
                        id={projectId}
                        onClose={() =>
                            setRemarkModal(false)
                        }
                        projectRemarks={
                            projectRemarks
                        }
                    />
                )}
                {openStatusLogModal && (
                    <StatusChangeLogModal
                        open={openStatusLogModal}
                        id={projectId}
                        onClose={() =>
                            setOpenStatusLogModal(
                                false,
                            )
                        }
                    />
                )}
                {openFutureOnboardingsModal && (
                    <FutureOnboardingsModal
                        open={
                            openFutureOnboardingsModal
                        }
                        id={projectId}
                        onClose={() =>
                            setOpenFutureuOnboardingsModal(
                                false,
                            )
                        }
                    />
                )}

                {openDeveloperModal && (
                    <DeveloperModal
                        open={openDeveloperModal}
                        onClose={
                            handleCloseDeveloperModal
                        }
                        pms={pms}
                        id={projectId}
                        removeDeveloper={
                            removeDeveloper
                        }
                        fetchAgain={getProjects}
                        isLoading1={isLoading1}
                        data={developerData}
                    />
                )}
                {openActionModal && (
                    <ActionDialog
                        open={openActionModal}
                        onClose={() =>
                            setOpenActionModal(
                                false,
                            )
                        }
                        editHandler={editHandler}
                        assignPMHandler={
                            assignPMHandler
                        }
                        onBoardHandler={
                            onBoardHandler
                        }
                        unassignPMHandler={
                            unassignPMHandler
                        }
                        pmToUnassign={
                            pmToUnassign
                        }
                        remarkHandler={
                            remarkHandler
                        }
                        project={projects}
                        status={status1}
                        projectRemarks={
                            projectRemarks
                        }
                        statusChangeLogHandler={
                            statusChangeLogHandler
                        }
                        futureOnboardingsHandler={
                            futureOnboardingsHandler
                        }
                    />
                )}
                {openFilterDialog && (
                    <FilterDialog
                        open={openFilterDialog}
                        onClose={() =>
                            setOpenFilterDialog(
                                false,
                            )
                        }
                    />
                )}
            </Box>

            {/* EVENTS */}
            <Box
                style={{
                    backgroundColor: '#fff',
                    width: '100%',
                    height: '85vh ',
                    margin: '10px 0px',
                    padding:
                        '10px 32px 24px 32px',
                    borderRadius: '12px',
                }}
            >
                <Box sx={{ margin: '8px' }}>
                    <Typography
                        variant="h5"
                        sx={{
                            font: 'Montserrat',
                            fontSize: '32px',
                            fontWeight: '500',
                        }}
                    >
                        Events
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '90%',
                    }}
                >
                    <DataGrid
                        rows={eventsRow}
                        getRowId={(row) =>
                            row?.key
                        }
                        columns={eventColumns}
                        pageSize={pageSize}
                        rowHeight={100}
                        pagination
                        onPageSizeChange={(
                            newPageSize,
                        ) =>
                            setPageSize(
                                newPageSize,
                            )
                        }
                        rowsPerPageOptions={[
                            10, 25, 50, 100,
                        ]}
                        experimentalFeatures={{
                            newEditingApi: true,
                        }}
                        disableColumnMenu
                        disableColumnFilter
                        sx={{
                            '.MuiDataGrid-columnSeparator':
                                {
                                    display:
                                        'none',
                                },
                            '&.MuiDataGrid-root':
                                {
                                    border: 'none',
                                },
                            'backgroundColor':
                                'white',
                            '& .MuiDataGrid-columnHeaders':
                                {
                                    // fontSize: "1rem",
                                    background:
                                        '#FAFAFA',
                                    color: 'black',
                                },
                            '& .MuiDataGrid-sortIcon':
                                {
                                    color: 'white',
                                },
                            '& 	.MuiDataGrid-menuIconButton':
                                {
                                    color: 'white',
                                },
                            '& 	.MuiDataGrid-filterIcon':
                                {
                                    color: 'white',
                                },
                            '& .MuiDataGrid-window':
                                {
                                    // Customize the entire grid window (both horizontal and vertical scrollbars)
                                    // For example:
                                    'scrollbarWidth':
                                        'thin', // For Firefox
                                    '&::-webkit-scrollbar':
                                        {
                                            width: '10px', // Width of the scrollbar
                                        },
                                    '&::-webkit-scrollbar-track':
                                        {
                                            background:
                                                '#f1f1f1', // Background color of the scrollbar track
                                        },
                                    '&::-webkit-scrollbar-thumb':
                                        {
                                            background:
                                                '#888', // Color of the scrollbar thumb
                                            borderRadius:
                                                '5px', // Border radius of the scrollbar thumb
                                        },
                                    '&::-webkit-scrollbar-thumb:hover':
                                        {
                                            background:
                                                '#555', // Color of the scrollbar thumb on hover
                                        },
                                },
                            '& .MuiDataGrid-virtualScroller':
                                {
                                    // Customize the vertical scrollbar container
                                    // For example:
                                    background:
                                        'white', // Background color of the vertical scrollbar container
                                    width: '10px', // Width of the vertical scrollbar container
                                },
                            '& .MuiDataGrid-horizontalScroller':
                                {
                                    // Customize the horizontal scrollbar container
                                    // For example:
                                    background:
                                        '#f1f1f1', // Background color of the horizontal scrollbar container
                                    height: '10px', // Height of the horizontal scrollbar container
                                },
                            '& .MuiDataGrid-scrollbar':
                                {
                                    // Styles for both vertical and horizontal scrollbar thumbs
                                    // Customize the common styles for the scrollbar thumb
                                    // For example:
                                    background:
                                        '#888', // Color of the scrollbar thumb
                                    borderRadius:
                                        '5px', // Border radius of the scrollbar thumb
                                },
                            '& .MuiDataGrid-scrollbar-thumb':
                                {
                                    // Styles for scrollbar thumb itself
                                    // You can further customize the styles for the scrollbar thumb
                                    // For example:
                                    'background':
                                        '#888', // Color of the scrollbar thumb
                                    'borderRadius':
                                        '5px', // Border radius of the scrollbar thumb
                                    '&:hover': {
                                        background:
                                            'red', // Color of the scrollbar thumb on hover
                                    },
                                },
                        }}
                        className={
                            classes.customScrollbar
                        }
                        filterModel={{
                            items: [
                                {
                                    columnField:
                                        'projectName',
                                    operatorValue:
                                        'contains',
                                    value: `${projectName}`,
                                },
                            ],
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps:
                                    {
                                        debounceMs: 500,
                                    },
                            },
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};
export default Projects;
