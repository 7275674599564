import { Badge } from '@mui/base';
import React from 'react';

const Status = (props) => {
    const { isActive = true, className = '' } =
        props;

    const statusColor = isActive
        ? '#2E9E46'
        : '#E03431';

    const customClassName = `!w-[50px] !h-[50px] bg-red-500 ${statusColor} ${className}`;

    const sx = {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        background: statusColor,
    };

    return (
        <div className="flex items-center gap-[8px]">
            <div
                className="flex items-center "
                style={{ gap: '4px' }}
            >
                Status:
                <div
                    className="flex items-center"
                    style={{ gap: '4px' }}
                >
                    <div
                        className=""
                        style={sx}
                    ></div>
                    {isActive
                        ? ' Active'
                        : ' Inactive'}
                </div>
            </div>
        </div>
    );
};

export { Status };
