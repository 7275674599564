import React, {
    useEffect,
    useState,
} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Divider,
    TextField,
    MenuItem,
    Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    getTechStacks,
    getAllProjectManagers,
    getGroups,
} from '../../../Redux/Slices/Lists/ListsSlice';
import { setFilterParameters } from '../../../Redux/Slices/Project/ProjectsSlice';
import { getProjects } from '../../../Redux/Slices/Project/ProjectsSlice';

const useStyles = makeStyles({
    container: {
        gap: '10px',
    },
    inputItem: {
        width: '100%',
    },
    paper: { minWidth: '500px' },
    customScrollbar: {
        'scrollbarWidth': 'thin',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
});

const FilterDialog = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const role = localStorage.getItem(
        'designation',
    );
    const { projectManagers } = useSelector(
        (store) => store?.lists,
    );
    const searchFilters = useSelector(
        (state) => state?.project.searchFilters,
    );
    const [filterParams, setFilterParams] =
        useState({ ...searchFilters });
    const { groups } = useSelector(
        (store) => store?.lists,
    );

    const groupList = groups.map((group) => {
        return {
            groupName: group.group[0].groupName,
            groupId: group.group[0]._id,
        };
    });

    const projectType = [
        {
            value: 'Fixed_Scope_Budget',
            label: 'Fixed Scope Budget',
        },
        {
            value: 'Fixed_Scope_Premium',
            label: 'Fixed Scope Premium',
        },
        {
            value: 'Developers_On_Demand',
            label: 'Developers On Demand',
        },
        {
            value: 'Dedicated_Team_Model',
            label: 'Dedicated Team Model',
        },
        {
            value: 'Shared_Tech_Team',
            label: 'Shared Tech Team',
        },
        {
            value: 'Monthly_Maintenance',
            label: 'Monthly Maintenance',
        },
    ];

    const invoiceType = [
        {
            value: 'Antino India',
            label: 'Antino India',
        },
        {
            value: ' Antino USA',
            label: ' Antino USA',
        },
        {
            value: 'Antino UAE',
            label: 'Antino UAE',
        },
        {
            value: 'Antino UK',
            label: 'Antino UK',
        },
        {
            value: 'Goldenflitch',
            label: 'Goldenflitch',
        },
        {
            value: 'other',
            label: 'other',
        },
    ];

    useEffect(() => {
        dispatch(getTechStacks());
        dispatch(
            getAllProjectManagers({ role: 'PM' }),
        );
        dispatch(getGroups());
    }, []);

    const handleChangeProjectManagers = (
        value,
    ) => {
        setFilterParams({
            ...filterParams,
            projectManagers: value,
        });
    };

    const handleChangeGroups = (value) => {
        setFilterParams({
            ...filterParams,
            group: value,
        });
    };

    const handleChangeTypeOfProject = (value) => {
        setFilterParams({
            ...filterParams,
            typeOfProject: value,
        });
    };
    // handle invoice
    const handleChangeInvoiceOfProject = (
        value,
    ) => {
        console.log(value);
        setFilterParams({
            ...filterParams,
            invoice: value,
        });
    };

    const handleChangeStatus = (event) => {
        setFilterParams({
            ...filterParams,
            status: event.target.value,
        });
    };

    const handleSubmit = () => {
        dispatch(
            setFilterParameters(filterParams),
        );
        dispatch(getProjects());
        onClose();
    };

    return (
        <Dialog
            className={classes.paper}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    minHeight: '30vh',
                    borderRadius: '16px',
                },
            }}
        >
            <DialogTitle>
                <Grid
                    container
                    sx={{
                        justifyContent:
                            'space-between',
                        alignItems: 'center',
                        font: 'Montserrat',
                        fontSize: '24px',
                        fontWeight: '500',
                        lineHeight: '30px',
                    }}
                >
                    Filter
                    <Button
                        sx={{
                            color: 'black',
                            fontSize: '1rem',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={onClose}
                        />
                    </Button>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent
                className={
                    classes.customScrollbar
                }
            >
                <div className=" ml-3 b mt-4 ">
                    <form onSubmit={handleSubmit}>
                        {(role === 'AVP' ||
                            role === 'VP' ||
                            role === 'CTO' ||
                            role === 'COO' ||
                            role === 'CEO') && (
                            <div className="flex justify-between items-center mt-4">
                                <Autocomplete
                                    multiple
                                    id="project-managers"
                                    options={
                                        projectManagers ||
                                        []
                                    }
                                    getOptionLabel={(
                                        option,
                                    ) =>
                                        option.fullName
                                    }
                                    value={
                                        filterParams.projectManagers
                                    }
                                    onChange={(
                                        _,
                                        newValue,
                                    ) =>
                                        handleChangeProjectManagers(
                                            newValue,
                                        )
                                    }
                                    renderInput={(
                                        params,
                                    ) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Project Managers"
                                            size="small"
                                            style={{
                                                width: 350,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        <div className="flex justify-between items-center mt-4">
                            <TextField
                                label="Status"
                                select
                                variant="outlined"
                                style={{
                                    width: 350,
                                }}
                                value={
                                    filterParams.status
                                }
                                onChange={
                                    handleChangeStatus
                                }
                                size="small"
                                // defaultValue={searchFilters?.selectedTechStack}
                            >
                              {/* added All */}
                              <MenuItem
                                    key={1}
                                    value="All"
                                    // disabled={techStack.disabled}
                                >
                                    All
                                </MenuItem>
                                <MenuItem
                                    key={2}
                                    value="Active"
                                    // disabled={techStack.disabled}
                                >
                                    Active
                                </MenuItem>
                                <MenuItem
                                    key={3}
                                    value="Inactive"
                                    // disabled={techStack.disabled}
                                >
                                    Inactive
                                </MenuItem>
                                <MenuItem
                                    key={4}
                                    value="Onhold"
                                    // disabled={techStack.disabled}
                                >
                                    Onhold
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <Autocomplete
                                multiple
                                id="group"
                                options={
                                    groupList ||
                                    []
                                }
                                getOptionLabel={(
                                    option,
                                ) =>
                                    option.groupName
                                }
                                value={
                                    filterParams.group
                                }
                                filterSelectedOptions
                                onChange={(
                                    _,
                                    newValue,
                                ) =>
                                    handleChangeGroups(
                                        newValue,
                                    )
                                }
                                renderInput={(
                                    params,
                                ) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Groups"
                                        size="small"
                                        style={{
                                            width: 350,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <Autocomplete
                                multiple
                                id="typeOfProject"
                                options={
                                    projectType ||
                                    []
                                }
                                getOptionLabel={(
                                    option,
                                ) => option.label}
                                value={
                                    filterParams.typeOfProject
                                }
                                filterSelectedOptions
                                onChange={(
                                    _,
                                    newValue,
                                ) =>
                                    handleChangeTypeOfProject(
                                        newValue,
                                    )
                                }
                                renderInput={(
                                    params,
                                ) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Type Of Project"
                                        size="small"
                                        style={{
                                            width: 350,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <Autocomplete
                                multiple
                                id="invoice"
                                options={
                                    invoiceType ||
                                    []
                                }
                                getOptionLabel={(
                                    option,
                                ) => option.label}
                                value={
                                    filterParams.invoice
                                }
                                filterSelectedOptions
                                onChange={(
                                    _,
                                    newValue,
                                ) =>
                                    handleChangeInvoiceOfProject(
                                        newValue,
                                    )
                                }
                                renderInput={(
                                    params,
                                ) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Invoice"
                                        size="small"
                                        style={{
                                            width: 350,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent:
                        'space-around',
                }}
            >
                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        'color': 'white',
                        'backgroundColor':
                            '#4E79FA',
                        'width': '130px',
                        'height': '36px',
                        'borderRadius': '8px',
                        'padding': '0px 16px',
                        'transition':
                            'background-color 0.3s, color 0.3s',
                        '&:hover': {
                            backgroundColor:
                                'white',
                            color: '#4E79FA',
                        },
                    }}
                    onClick={handleSubmit}
                >
                    Apply Filter
                </Button>
                <Button
                    onClick={onClose}
                    sx={{
                        'color': '#4E79FA',
                        'backgroundColor':
                            'white',
                        'width': '130px',
                        'height': '36px',
                        'borderRadius': '8px',
                        'border':
                            '3px solid #F0F0F0',
                        'padding': '0px 16px',
                        'transition':
                            'background-color 0.3s, color 0.3s',
                        '&:hover': {
                            backgroundColor:
                                '#4E79FA',
                            color: 'white',
                        },
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
