import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Divider,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
    DesktopDatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import moment from 'moment';
import { addProject } from '../../../apis/ProjectApi';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { getProjects } from '../../../Redux/Slices/Project/ProjectsSlice';
import {
    getGroups,
    getTechStacks,
} from '../../../Redux/Slices/Lists/ListsSlice';
import { toast } from 'react-toastify';
import CreateProjectSchema from './CreateProjectSchema';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

const CreateProject = ({
    open,
    onClose,
    data,
}) => {
    const useStyles = makeStyles({
        container: {
            gap: '10px',
        },
        inputItem: {
            width: '100%',
        },
        paper: { minWidth: '500px' },
        customScrollbar: {
            'scrollbarWidth': 'thin', // For Firefox
            '&::-webkit-scrollbar': {
                width: '10px',
            },
            '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        },
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    const [eventValue, setEventValue] =
        useState('');
    const [eventList, setEventList] = useState(
        [],
    );
    const [
        estimatedEnddate,
        setEstimatedEnddate,
    ] = useState('');
    const [completionDate, setCompletionDate] =
        useState(null);
    const [eventName, setEventName] =
        useState('');
    const [isCompleted, setIsCompleted] =
        useState(false);

    const handleSubmit = async (values) => {
        const techStackIds =
            values?.techStack.map(
                (stack) => stack._id,
            );
        const groupId = values?.group?.groupId;
        setEventList([]);

        let payload = {
            projectName: values?.projectName,
            clientName: values?.clientName,
            startDate: values?.startDate,
            estimatedEndDate:
                values?.estimatedEndDate,
            techStack: techStackIds,
            typeOfProject: values?.typeOfProject,
            demoUrls: values?.demoUrls,
            clientPointOfContact:
                values?.clientPointOfContact,
            status: values?.status,
            description: values?.description,
            events: eventList,
            group: groupId,
            invoice: values?.invoice,
        };

        addProject(payload)
            .then((res) => {
                formik.handleReset();
                onClose();
                dispatch(getProjects('Active'));
                toast(res?.message);
            })
            .catch((error) =>
                toast(
                    error?.response?.data
                        ?.message,
                ),
            );
    };

    const formik = useFormik({
        initialValues: {
            projectName: '',
            clientName: '',
            startDate: '',
            estimatedEndDate: '',
            techStack: [],
            typeOfProject: '',
            demoUrls: '',
            clientPointOfContact: '',
            status: '',
            description: '',
            events: [],
            invoice: '',
        },
        validationSchema: CreateProjectSchema,

        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        dispatch(getTechStacks());
        dispatch(getGroups());
    }, []);

    const techStack = useSelector(
        (store) => store?.lists?.techStacks,
    );
    const { groups } = useSelector(
        (store) => store?.lists,
    );

    const groupList = groups.map((group) => {
        return {
            groupName: group.group[0].groupName,
            groupId: group.group[0]._id,
        };
    });

    const invoice = [
        'Antino India',
        ' Antino USA',
        'Antino UAE',
        'Antino UK',
        'Goldenflitch',
        'other',
    ];

    const projectType = [
        'Fixed Scope Budget',
        'Fixed Scope Premium',
        'Developers On Demand',
        'Dedicated Team Model',
        'Shared Tech Team',
        'Monthly Maintenance',
    ];

    const status = [
        'Active',
        'Inactive',
        'Onhold',
    ];

    const handleClose = () => {
        formik.handleReset();
        onClose();
    };

    const addEvent = () => {
        if (
            eventValue.trim() === '' ||
            estimatedEnddate.trim() === '' ||
            eventName.trim() === ''
        ) {
            return; // Don't add if eventValue or estimatedEnddate is empty
        }
        const newEvent = {
            estimatedEnddate,
            eventValue,
            completionDate,
            isCompleted,
            eventName,
        };
        setEventList([...eventList, newEvent]);
        setEventValue(''); // Clear the input field after adding a todo
        setEstimatedEnddate('');
        setEventName('');
    };

    const onChangeEventHandler = (e, index) => {
        const newEvents = eventList.map(
            (event, i) =>
                i === index
                    ? { ...event, eventValue: e }
                    : event,
        );
        setEventList(newEvents);
    };

    const onChangeEventNameHandler = (
        e,
        index,
    ) => {
        const newEvents = eventList.map(
            (event, i) =>
                i === index
                    ? { ...event, eventName: e }
                    : event,
        );
        setEventList(newEvents);
    };

    const onChangeDeadlineHandler = (
        e,
        index,
    ) => {
        const newEvents = eventList.map(
            (event, i) =>
                i === index
                    ? {
                          ...event,
                          estimatedEnddate: e,
                      }
                    : event,
        );
        setEventList(newEvents);
    };

    const deleteEventHandler = (index) => {
        const newEvents = eventList.filter(
            (event, i) => i !== index,
        );
        setEventList(newEvents);
    };

    const disableProjectType = projectType
        .slice(2, 5)
        .includes(formik.values.typeOfProject);

    return (
        <>
            <Box>
                <form
                    onSubmit={formik.handleSubmit}
                    autocomplete="off"
                >
                    <Dialog
                        className={classes.paper}
                        // maxWidth={"sm"}
                        open={open}
                        close={onClose}
                        PaperProps={{
                            sx: {
                                minHeight: '80vh',
                                // Set the maximum height of the Dialog content
                                borderRadius:
                                    '16px',
                            },
                        }}
                        sx={{
                            '& .MuiDialogActions-root':
                                {
                                    padding:
                                        '0px !important',
                                },
                        }}
                    >
                        <DialogTitle>
                            <Grid
                                container
                                sx={{
                                    justifyContent:
                                        'space-between',
                                    alignItems:
                                        'center',
                                    font: 'Montserrat',
                                    fontSize:
                                        '24px',
                                    fontWeight:
                                        '500',
                                    lineHeight:
                                        '30px',
                                    // borderBottom: "1px solid #F0F0F0",
                                }}
                            >
                                Create New Project
                                <Button
                                    sx={{
                                        color: 'black',
                                        fontSize:
                                            '1rem',
                                    }}
                                    onClick={
                                        handleClose
                                    }
                                >
                                    <CloseIcon
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={
                                            onClose
                                        }
                                    />
                                </Button>
                            </Grid>
                        </DialogTitle>
                        <Divider />

                        <DialogContent
                            className={
                                classes.customScrollbar
                            }
                        >
                            <Grid
                                container
                                // className={classes.container}
                                sx={{
                                    '& 	.MuiAutocomplete-popper':
                                        {
                                            paddingBottom:
                                                '1rem',
                                        },
                                    '& .MuiFormHelperText-root':
                                        {
                                            color: '#D32F2F',
                                        },
                                    // maxHeight: "50vh",
                                }}
                                mt={1}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Project
                                            Name
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: 'red',
                                                fontSize:
                                                    '12px',
                                                lineHeight:
                                                    '16px',
                                                marginLeft:
                                                    '2px',
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                    <TextField
                                        size="small"
                                        className={
                                            classes.inputItem
                                        }
                                        required
                                        value={
                                            formik
                                                .values
                                                .projectName
                                        }
                                        name="projectName"
                                        onChange={
                                            formik.handleChange
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        // label="Enter Project Name"
                                        //   error={formik.errors.projectName}
                                        helperText={
                                            formik
                                                .touched
                                                .projectName
                                                ? formik
                                                      .errors
                                                      .projectName
                                                : ''
                                        }
                                        autoComplete="off"
                                        placeholder="Enter Project Name"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Client
                                            Name
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: 'red',
                                                fontSize:
                                                    '12px',
                                                lineHeight:
                                                    '16px',
                                                marginLeft:
                                                    '2px',
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                    <TextField
                                        size="small"
                                        className={
                                            classes.inputItem
                                        }
                                        required
                                        value={
                                            formik
                                                .values
                                                .clientName
                                        }
                                        name="clientName"
                                        onChange={
                                            formik.handleChange
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        // label="Enter Client Name"
                                        //   error={formik.errors.clientName}
                                        helperText={
                                            formik
                                                .touched
                                                .clientName
                                                ? formik
                                                      .errors
                                                      .clientName
                                                : ''
                                        }
                                        autoComplete="off"
                                        placeholder="Enter Client Name"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Project
                                            Type
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: 'red',
                                                fontSize:
                                                    '12px',
                                                lineHeight:
                                                    '16px',
                                                marginLeft:
                                                    '2px',
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                    <Autocomplete
                                        options={
                                            projectType
                                        }
                                        disablePortal={
                                            true
                                        }
                                        className={
                                            classes.inputItem
                                        }
                                        name="typeOfProject"
                                        getOptionLabel={(
                                            option,
                                        ) =>
                                            option?.isDeleted
                                                ? ''
                                                : option
                                        }
                                        sx={{
                                            '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator':
                                                {
                                                    visibility:
                                                        'hidden',
                                                },
                                        }}
                                        // onChange={formik.handleChange}
                                        value={
                                            formik
                                                .values
                                                .typeOfProject
                                        }
                                        onChange={(
                                            event,
                                            value,
                                        ) => {
                                            formik.setFieldValue(
                                                'typeOfProject',
                                                value,
                                            );
                                            formik.setFieldValue(
                                                'startDate',
                                                '',
                                            );
                                            formik.setFieldValue(
                                                'estimatedEndDate',
                                                '',
                                            );
                                        }}
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        size="small"
                                        renderInput={(
                                            params,
                                        ) => (
                                            <TextField
                                                required
                                                {...params}
                                                value={
                                                    formik
                                                        .values
                                                        .typeOfProject
                                                }
                                                name="typeOfProject"
                                                // label="Select Project Type"
                                                // error={formik.errors.typeOfProject}
                                                helperText={
                                                    formik
                                                        .touched
                                                        .typeOfProject
                                                        ? formik
                                                              .errors
                                                              .typeOfProject
                                                        : ''
                                                }
                                                placeholder="Select Project Type"
                                            />
                                        )}
                                    />
                                </Grid>
                                <LocalizationProvider
                                    dateAdapter={
                                        AdapterDateFns
                                    }
                                >
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <Box
                                            sx={{
                                                display:
                                                    'flex',
                                                alignItems:
                                                    'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    fontFamily:
                                                        'Lato',
                                                    fontWeight:
                                                        '500',
                                                    fontSize:
                                                        '15px',
                                                    lineHeight:
                                                        '25px',
                                                }}
                                            >
                                                Project
                                                Start
                                                Date
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    color: 'red',
                                                    fontSize:
                                                        '12px',
                                                    lineHeight:
                                                        '16px',
                                                    marginLeft:
                                                        '2px',
                                                }}
                                            >
                                                *
                                            </Typography>
                                        </Box>
                                        <DesktopDatePicker
                                            inputFormat="MM/dd/yyyy"
                                            required
                                            // label="Select Project Start date"
                                            sx={{
                                                width: '100%',
                                            }}
                                            slotProps={{
                                                textField:
                                                    {
                                                        readOnly: true,
                                                    },
                                            }}
                                            value={
                                                new Date(
                                                    formik.values.startDate,
                                                )
                                            }
                                            onChange={(
                                                newValue,
                                            ) => {
                                                formik.setFieldValue(
                                                    'startDate',
                                                    moment(
                                                        newValue,
                                                    ).format(
                                                        'MM-DD-YYYY',
                                                    ),
                                                );

                                                if (
                                                    disableProjectType
                                                ) {
                                                    formik.setFieldValue(
                                                        'estimatedEndDate',
                                                        moment(
                                                            newValue,
                                                        )
                                                            .add(
                                                                1,
                                                                'y',
                                                            )
                                                            .format(
                                                                'MM-DD-YYYY',
                                                            ),
                                                    );
                                                    return;
                                                }
                                            }}
                                            invalidDateMessage="Computer says no"
                                            helperText={
                                                formik
                                                    .touched
                                                    .startDate &&
                                                formik
                                                    .errors
                                                    .startDate
                                                    ? formik
                                                          .errors
                                                          .startDate
                                                    : ''
                                            }
                                            error={
                                                formik
                                                    .touched
                                                    .startDate &&
                                                !!formik
                                                    .errors
                                                    .startDate
                                            }
                                            // renderInput={(params) => (
                                            //   <TextField
                                            //     size="small"
                                            //     required
                                            //     fullWidth
                                            //     {...params}
                                            //     onBlur={(e) => {
                                            //       formik.setFieldTouched("startDate", true);
                                            //     }}
                                            //     helperText={
                                            //       formik.touched.startDate || formik.errors.startDate
                                            //         ? formik.errors.startDate
                                            //         : ""
                                            //     }
                                            //   />
                                            // )}
                                        />
                                        {formik
                                            .touched
                                            .startDate &&
                                            formik
                                                .errors
                                                .startDate && (
                                                <div
                                                    style={{
                                                        color: '#E06B6B',
                                                        fontSize:
                                                            '12px',
                                                        lineHeight:
                                                            '16px',
                                                    }}
                                                >
                                                    {
                                                        formik
                                                            .errors
                                                            .startDate
                                                    }
                                                </div>
                                            )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <Box
                                            sx={{
                                                display:
                                                    'flex',
                                                alignItems:
                                                    'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    fontFamily:
                                                        'Lato',
                                                    fontWeight:
                                                        '500',
                                                    fontSize:
                                                        '15px',
                                                    lineHeight:
                                                        '25px',
                                                }}
                                            >
                                                Project
                                                End
                                                Date
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    color: 'red',
                                                    fontSize:
                                                        '12px',
                                                    lineHeight:
                                                        '16px',
                                                    marginLeft:
                                                        '2px',
                                                }}
                                            >
                                                *
                                            </Typography>
                                        </Box>
                                        <DesktopDatePicker
                                            // label="Select Estimated End date "
                                            disabled={
                                                !formik
                                                    .values
                                                    .startDate ||
                                                disableProjectType
                                            }
                                            shouldDisableDate={(
                                                date,
                                            ) =>
                                                moment(
                                                    date,
                                                ).isBefore(
                                                    moment(
                                                        formik
                                                            .values
                                                            .startDate,
                                                    ).add(
                                                        1,
                                                        'day',
                                                    ),
                                                )
                                            }
                                            inputFormat="MM/dd/yyyy"
                                            sx={{
                                                width: '100%',
                                            }}
                                            slotProps={{
                                                textField:
                                                    {
                                                        readOnly: true,
                                                    },
                                            }}
                                            value={
                                                new Date(
                                                    formik.values.estimatedEndDate,
                                                )
                                            }
                                            onChange={(
                                                newValue,
                                            ) =>
                                                formik.setFieldValue(
                                                    'estimatedEndDate',
                                                    moment(
                                                        newValue,
                                                    ).format(
                                                        'MM-DD-YYYY',
                                                    ),
                                                )
                                            }
                                            onBlur={
                                                formik.handleBlur
                                            }
                                        />
                                    </Grid>
                                    {formik
                                        .touched
                                        .estimatedEndDate &&
                                        formik
                                            .errors
                                            .estimatedEndDate && (
                                            <div
                                                style={{
                                                    color: '#E06B6B',
                                                    fontSize:
                                                        '12px',
                                                    lineHeight:
                                                        '16px',
                                                }}
                                            >
                                                {
                                                    formik
                                                        .errors
                                                        .estimatedEndDate
                                                }
                                            </div>
                                        )}
                                </LocalizationProvider>

                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Tech
                                            Stack
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: 'red',
                                                fontSize:
                                                    '12px',
                                                lineHeight:
                                                    '16px',
                                                marginLeft:
                                                    '2px',
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                    <Autocomplete
                                        className={
                                            classes.inputItem
                                        }
                                        options={
                                            techStack ||
                                            []
                                        }
                                        multiple
                                        disablePortal={
                                            true
                                        }
                                        getOptionLabel={(
                                            option,
                                        ) =>
                                            option.name
                                        }
                                        filterSelectedOptions
                                        onChange={(
                                            event,
                                            value,
                                        ) =>
                                            formik.setFieldValue(
                                                'techStack',
                                                value,
                                            )
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        value={
                                            formik
                                                ?.values
                                                ?.techStack
                                        }
                                        size="small"
                                        renderInput={(
                                            params,
                                        ) => (
                                            <TextField
                                                required
                                                {...params}
                                                name="techStack"
                                                // label="Select Tech Stack"
                                                // error={formik.errors.techStack}
                                                helperText={
                                                    formik
                                                        .touched
                                                        .techStack
                                                        ? formik
                                                              .errors
                                                              .techStack
                                                        : ''
                                                }
                                                placeholder="Select Tech Stack"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Status
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: 'red',
                                                fontSize:
                                                    '12px',
                                                lineHeight:
                                                    '16px',
                                                marginLeft:
                                                    '2px',
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                    <Autocomplete
                                        options={
                                            status
                                        }
                                        disablePortal={
                                            true
                                        }
                                        className={
                                            classes.inputItem
                                        }
                                        name="status"
                                        getOptionLabel={(
                                            option,
                                        ) =>
                                            option?.isDeleted
                                                ? ''
                                                : option
                                        }
                                        sx={{
                                            '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator':
                                                {
                                                    visibility:
                                                        'hidden',
                                                },
                                        }}
                                        value={
                                            formik
                                                .values
                                                .status
                                        }
                                        onChange={(
                                            event,
                                            value,
                                        ) =>
                                            formik.setFieldValue(
                                                'status',
                                                value,
                                            )
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        size="small"
                                        renderInput={(
                                            params,
                                        ) => (
                                            <TextField
                                                required
                                                {...params}
                                                value={
                                                    formik
                                                        .values
                                                        .status
                                                }
                                                name="status"
                                                // label="Select Status"
                                                // error={formik.errors.status}
                                                helperText={
                                                    formik
                                                        .touched
                                                        .status
                                                        ? formik
                                                              .errors
                                                              .status
                                                        : ''
                                                }
                                                placeholder="Select Status"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Client
                                            Contact
                                            Details
                                        </Typography>
                                    </Box>
                                    <TextField
                                        size="small"
                                        className={
                                            classes.inputItem
                                        }
                                        value={
                                            formik
                                                .values
                                                .clientPointOfContact
                                        }
                                        name="clientPointOfContact"
                                        onChange={
                                            formik.handleChange
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        // label="Client Contact Details"
                                        //   error={formik.errors.clientPointOfContact}
                                        helperText={
                                            formik
                                                .touched
                                                .clientPointOfContact &&
                                            formik
                                                .errors
                                                .clientPointOfContact
                                                ? formik
                                                      .errors
                                                      .clientPointOfContact
                                                : ''
                                        }
                                        placeholder="Enter Phone Number or Email Address"
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontFamily:
                                                'Lato',
                                            fontWeight:
                                                '500',
                                            fontSize:
                                                '15px',
                                            lineHeight:
                                                '25px',
                                        }}
                                    >
                                        Demo URL
                                    </Typography>
                                    <TextField
                                        size="small"
                                        className={
                                            classes.inputItem
                                        }
                                        value={
                                            formik
                                                .values
                                                .demoUrls
                                        }
                                        name="demoUrls"
                                        onChange={
                                            formik.handleChange
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        // label="Enter Demo urls"
                                        //   error={formik.errors.demoUrls}
                                        helperText={
                                            formik
                                                .touched
                                                .demoUrls
                                                ? formik
                                                      .errors
                                                      .demoUrls
                                                : ''
                                        }
                                        autoComplete="off"
                                        placeholder="Enter Demo URL"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Groups
                                        </Typography>
                                    </Box>
                                    <Autocomplete
                                        options={
                                            groupList ||
                                            []
                                        }
                                        className={
                                            classes.inputItem
                                        }
                                        name="group"
                                        getOptionLabel={(
                                            option,
                                        ) =>
                                            option?.groupName
                                        }
                                        onChange={(
                                            event,
                                            value,
                                        ) =>
                                            formik.setFieldValue(
                                                'group',
                                                value,
                                            )
                                        }
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        value={
                                            formik
                                                ?.values
                                                ?.group ||
                                            null
                                        } // Ensure value is null if group is undefined
                                        size="small"
                                        renderInput={(
                                            params,
                                        ) => (
                                            <TextField
                                                {...params}
                                                value={
                                                    formik
                                                        ?.values
                                                        ?.group
                                                        ? formik
                                                              ?.values
                                                              ?.group
                                                              .groupName
                                                        : ''
                                                } // Show groupName if group is selected
                                                name="group"
                                                helperText={
                                                    formik
                                                        .touched
                                                        ?.group
                                                        ? formik
                                                              .errors
                                                              ?.group
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* invoice dropdown */}

                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Invoice
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: 'red',
                                                fontSize:
                                                    '12px',
                                                lineHeight:
                                                    '16px',
                                                marginLeft:
                                                    '2px',
                                            }}
                                        >
                                            *
                                        </Typography>
                                    </Box>
                                    <Autocomplete
                                        options={
                                            invoice
                                        }
                                        disablePortal={
                                            true
                                        }
                                        className={
                                            classes.inputItem
                                        }
                                        name="invoice"
                                        getOptionLabel={(
                                            option,
                                        ) =>
                                            option?.isDeleted
                                                ? ''
                                                : option
                                        }
                                        sx={{
                                            '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator':
                                                {
                                                    visibility:
                                                        'hidden',
                                                },
                                        }}
                                        // onChange={formik.handleChange}
                                        value={
                                            formik
                                                .values
                                                .invoice
                                        }
                                        onChange={(
                                            event,
                                            value,
                                        ) => {
                                            formik.setFieldValue(
                                                'invoice',
                                                value,
                                            );
                                        }}
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        size="small"
                                        renderInput={(
                                            params,
                                        ) => (
                                            <TextField
                                                required
                                                {...params}
                                                value={
                                                    formik
                                                        .values
                                                        .invoice
                                                }
                                                name="invoice"
                                                // label="Select Project Type"
                                                // error={formik.errors.typeOfProject}
                                                helperText={
                                                    formik
                                                        .touched
                                                        .isDeletednvoice
                                                        ? formik
                                                              .errors
                                                              .invoice
                                                        : ''
                                                }
                                                placeholder="Select Invoice"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                'flex',
                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontFamily:
                                                    'Lato',
                                                fontWeight:
                                                    '500',
                                                fontSize:
                                                    '15px',
                                                lineHeight:
                                                    '25px',
                                            }}
                                        >
                                            Add
                                            Description
                                        </Typography>
                                    </Box>
                                    <TextArea
                                        size="small"
                                        className={
                                            classes.inputItem
                                        }
                                        value={
                                            formik
                                                .values
                                                .description
                                        }
                                        name="description"
                                        onChange={
                                            formik.handleChange
                                        }
                                        // label="Description(500 words)"
                                        onBlur={
                                            formik.handleBlur
                                        }
                                        helperText={
                                            formik
                                                .touched
                                                .description
                                                ? formik
                                                      .errors
                                                      .description
                                                : ''
                                        }
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontFamily:
                                                'Lato',
                                            fontWeight:
                                                '500',
                                            fontSize:
                                                '15px',
                                            lineHeight:
                                                '25px',
                                        }}
                                    >
                                        Add Events
                                    </Typography>
                                    <div
                                        style={{
                                            display:
                                                'flex',
                                            flexDirection:
                                                'column',
                                            boxShadow:
                                                '0px 5px 10px rgba(11, 10, 10, 0.3)',
                                            padding:
                                                '16px 24px 16px 24px',
                                            gap: '0.3rem',
                                            marginBotton:
                                                '1rem',
                                            borderRadius:
                                                '16px',
                                        }}
                                    >
                                        <textarea
                                            type="text"
                                            value={
                                                eventName
                                            }
                                            onChange={(
                                                e,
                                            ) =>
                                                setEventName(
                                                    e
                                                        .target
                                                        .value,
                                                )
                                            }
                                            placeholder="Event Name"
                                            style={{
                                                border: '2px solid #F0F0F0',
                                                borderRadius:
                                                    '4px',
                                                padding:
                                                    '6px',
                                                width: '50%',
                                                height: '2.5rem',
                                            }}
                                            maxLength="25"
                                            disabled={
                                                eventList.length ===
                                                5
                                            }
                                        />
                                        <div
                                            style={{
                                                display:
                                                    'flex',
                                                alignItems:
                                                    'center',
                                                gap: '1rem',
                                                fontSize:
                                                    '14px',
                                            }}
                                        >
                                            <p>
                                                Estimated
                                                End
                                                Date
                                            </p>
                                            <input
                                                type="date"
                                                value={
                                                    estimatedEnddate
                                                }
                                                onChange={(
                                                    e,
                                                ) =>
                                                    setEstimatedEnddate(
                                                        e
                                                            .target
                                                            .value,
                                                    )
                                                }
                                                placeholder="Add a deadline date"
                                                style={{
                                                    border: '2px solid #F0F0F0',
                                                    borderRadius:
                                                        '4px',
                                                    padding:
                                                        '6px',
                                                }}
                                                min={dayjs(
                                                    formik
                                                        .values
                                                        .startDate,
                                                ).format(
                                                    'YYYY-MM-DD',
                                                )}
                                                onKeyDown={(
                                                    e,
                                                ) =>
                                                    e.preventDefault()
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display:
                                                    'flex',
                                                gap: '0.1rem',
                                            }}
                                        >
                                            <textarea
                                                type="text"
                                                value={
                                                    eventValue
                                                }
                                                onChange={(
                                                    e,
                                                ) =>
                                                    setEventValue(
                                                        e
                                                            .target
                                                            .value,
                                                    )
                                                }
                                                placeholder="Event Description"
                                                style={{
                                                    border: '2px solid #F0F0F0',
                                                    borderRadius:
                                                        '4px',
                                                    padding:
                                                        '6px',
                                                    marginBottom:
                                                        '1rem',
                                                    width: '90%',
                                                    height: '2.5rem',
                                                }}
                                                disabled={
                                                    eventList.length ===
                                                    5
                                                }
                                            />
                                            <button
                                                onClick={
                                                    addEvent
                                                }
                                                className="add-btn"
                                                disabled={
                                                    eventValue.trim() ===
                                                    ''
                                                }
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <ul>
                                        {eventList.map(
                                            (
                                                event,
                                                index,
                                            ) => (
                                                <li
                                                    key={
                                                        index
                                                    }
                                                    style={{
                                                        display:
                                                            'flex',
                                                        flexDirection:
                                                            'column',
                                                        boxShadow:
                                                            '0px 5px 10px rgba(11, 10, 10, 0.3)',
                                                        padding:
                                                            '16px 24px 16px 24px',
                                                        gap: '0.3rem',
                                                        marginBotton:
                                                            '1rem',
                                                        borderRadius:
                                                            '16px',
                                                        marginTop:
                                                            '1rem',
                                                    }}
                                                >
                                                    <input
                                                        value={
                                                            event.eventName
                                                        }
                                                        onChange={(
                                                            e,
                                                        ) =>
                                                            onChangeEventNameHandler(
                                                                e
                                                                    .target
                                                                    .value,
                                                                index,
                                                            )
                                                        }
                                                        style={{
                                                            border: '2px solid #F0F0F0',
                                                            borderRadius:
                                                                '4px',
                                                            padding:
                                                                '6px',
                                                            width: '50%',
                                                        }}
                                                    ></input>
                                                    <div
                                                        style={{
                                                            display:
                                                                'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '1rem',
                                                            fontSize:
                                                                '14px',
                                                        }}
                                                    >
                                                        <p>
                                                            Estimated
                                                            End
                                                            Date
                                                        </p>
                                                        <input
                                                            value={
                                                                event.estimatedEnddate
                                                            }
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                onChangeDeadlineHandler(
                                                                    e
                                                                        .target
                                                                        .value,
                                                                    index,
                                                                )
                                                            }
                                                            style={{
                                                                border: '2px solid #F0F0F0',
                                                                borderRadius:
                                                                    '4px',
                                                                padding:
                                                                    '6px',
                                                            }}
                                                            disabled={
                                                                true
                                                            }
                                                        ></input>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display:
                                                                'flex',
                                                            gap: '0.1rem',
                                                        }}
                                                    >
                                                        <input
                                                            value={
                                                                event.eventValue
                                                            }
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                onChangeEventHandler(
                                                                    e
                                                                        .target
                                                                        .value,
                                                                    index,
                                                                )
                                                            }
                                                            style={{
                                                                border: '2px solid #F0F0F0',
                                                                borderRadius:
                                                                    '4px',
                                                                padding:
                                                                    '6px',
                                                                width: '90%',
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        ></input>
                                                        <button
                                                            onClick={() =>
                                                                deleteEventHandler(
                                                                    index,
                                                                )
                                                            }
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius:
                                                                    '50%',
                                                                border: 'none',
                                                                color: 'white',
                                                                backgroundColor:
                                                                    'red',
                                                                fontWeight:
                                                                    'bolder',
                                                                fontSize:
                                                                    'larger',
                                                                marginLeft:
                                                                    '10px',
                                                                cursor: 'pointer',
                                                                boxShadow:
                                                                    '0px 5px 10px rgba(11, 10, 10, 0.3)',
                                                                transition:
                                                                    '0.3s',
                                                                opacity:
                                                                    '0.8',
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </button>
                                                    </div>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Box className="mr-2 mb-4 mt-16">
                                <Button
                                    onClick={
                                        handleClose
                                    }
                                    // sx={{ color: "blue", width: "90%" }}
                                    size="medium"
                                    variant="contained"
                                    sx={{
                                        'color':
                                            '#4E79FA',
                                        'backgroundColor':
                                            'white',
                                        'width':
                                            '260px',
                                        'height':
                                            '46px',
                                        'borderRadius':
                                            '8px',
                                        'padding':
                                            '0px 36px',
                                        // gap: "8px",
                                        'transition':
                                            'background-color 0.3s, color 0.3s', // Add transition for smooth effect
                                        '&:hover':
                                            {
                                                backgroundColor:
                                                    '#4E79FA',
                                                color: 'white',
                                            },
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box className="mr-10 mb-4 mt-16">
                                <Button
                                    onClick={
                                        formik.handleSubmit
                                    }
                                    variant="contained"
                                    disabled={
                                        !formik.isValid
                                    } // Disable the button if the form is not valid
                                    sx={{
                                        'width':
                                            '260px',
                                        'height':
                                            '46px',
                                        'borderRadius':
                                            '8px',
                                        'padding':
                                            '0px 36px',
                                        'transition':
                                            'background-color 0.3s, color 0.3s', // Add transition for smooth effect
                                        'backgroundColor':
                                            '#4E79FA',
                                        'color':
                                            'white',
                                        '&:hover':
                                            {
                                                backgroundColor:
                                                    'white',
                                                color: '#4E79FA',
                                            },
                                    }}
                                >
                                    Create Project
                                </Button>
                            </Box>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </>
    );
};

export default CreateProject;
