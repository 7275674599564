import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import api from '../../../apiclient';

const initialState = {
    isLoading: false,
    isLoading1: false,
    error: '',
    projects: [],
    dataPerPage: 1,
    totalCount: 0,
    currentPage: 1,
    searchFilters: {},
    projectRemarks: [],
    displayRemarksLoading: false,
    statusChangeLogs: [],
    futureOnboardingsLogs: [],
    developerData: [],
    offboardedDevelopers: [],
    onboardedDevelopers: [],
    projectsData: '',
};

// export const getProjects = createAsyncThunk(
//     'project/getProjects',
//     async (status, thunkAPI) => {
//         const { searchFilters, projectsData } =
//             thunkAPI.getState().project;
//         const PMIds =
//             searchFilters.projectManagers?.map(
//                 (pm) => pm._id,
//             );
//         const groupIds = searchFilters.group?.map(
//             (group) => group.groupId,
//         );
//         const typeOfProjectList =
//             searchFilters?.typeOfProject?.map(
//                 (typeOfProject) =>
//                     typeOfProject.value,
//             );
//         // added invoice in params
//         const invoiceList  =
//         searchFilters?.invoice?.map(
//             (invoice) =>
//                 invoice.value,
//         );
//         // const invoiceList =
//         //     searchFilters?.invoice;
//         try {
//             let res = await api.get(
//                 `api/v2/projects/viewall?&status=${
//                     searchFilters.status
//                         ? searchFilters.status
//                         : 'Active'
//                 }&reportingPm=${
//                     PMIds ? PMIds : ''
//                 }&group=${
//                     groupIds ?? ''
//                 }&typeOfProject=${
//                     typeOfProjectList ?? ''
//                 }&latestProjects=${
//                     projectsData
//                         ? projectsData
//                         : ''
//                 }&invoice=${invoiceList ?? ''}`,
//             );

//             return res?.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(
//                 error,
//             );
//         }
//     },
// );

export const getProjects = createAsyncThunk(
  'project/getProjects',
  async (status, thunkAPI) => {
      const { searchFilters, projectsData } = thunkAPI.getState().project;
     
      
      const params = {
          status: searchFilters.status || '',
          reportingPm: searchFilters.projectManagers?.map(pm => pm._id) || [],
          group: searchFilters.group?.map(group => group.groupId) || [],
          typeOfProject: searchFilters.typeOfProject?.map(type => type.value) || [],
          latestProjects: projectsData || [],
          invoice: searchFilters.invoice?.map(invoice => invoice.value) || []
      };

       const filteredParams = Object.fromEntries(
          Object.entries(params).filter(([_, value]) => Array.isArray(value) ? value.length > 0 : value)
      );

      // Construct the query string
      const queryString = new URLSearchParams(filteredParams).toString();

      try {
          let res = await api.get(`api/v2/projects/viewall?${queryString}`);
          return res?.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  },
);



export const searchProject = createAsyncThunk(
    'project/searchProject',
    async (
        { dataPerPage, currentPage, searchField },
        thunkAPI,
    ) => {
        try {
            let res = await api.post(
                `project/api/v1/projects/search`,
                {
                    keyword: searchField,
                    // page: currentPage,
                    // limit: dataPerPage,
                },
            );
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error,
            );
        }
    },
);

export const removeDeveloper = createAsyncThunk(
    'project/removeDeveloper',
    async (data, thunkAPI) => {
        try {
            const remove_url =
                'api/v2/projects/offboard';
            let res = await api.post(
                `${remove_url}`,
                {
                    boardingType:
                        data?.boardingType,
                    projectId: data?.projectId,
                    userId: data?.userId
                        ? data?.userId
                        : data.fullTimeUserId[0],
                    offBoardingDate:
                        data?.offBoardingDate,
                    remark: data?.remark,
                },
            );
            return res?.data;
        } catch (error) {
            throw thunkAPI.rejectWithValue(
                error.response.data,
            );
        }
    },
);

export const addProjectRemark = createAsyncThunk(
    'project/addProjectRemark',
    async (data, thunkAPI) => {
        try {
            let response = await api.post(
                `api/v2/projects/addRemark`,
                data,
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response.data,
            );
        }
    },
);

export const getProjectRemark = createAsyncThunk(
    'project/getProjectRemark',
    async (data, thunkAPI) => {
        try {
            let response = await api.get(
                `api/v2/projects/getRemarks?projectId=${data.projectId}`,
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response.data,
            );
        }
    },
);

export const getStatusChangeLogs =
    createAsyncThunk(
        'project/getStatusChangeLogs',
        async (data, thunkAPI) => {
            try {
                let response = await api.get(
                    `api/v2/projects/getProjectStatusLogs?projectId=${data.projectId}`,
                );
                return response.data;
            } catch (error) {
                return thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const getFutureOnboardingsLogs =
    createAsyncThunk(
        'project/getFutureOnboardingsLogs',
        async (data, thunkAPI) => {
            try {
                let response = await api.get(
                    `api/v2/projects/getFutureOnboardings?projectId=${data.projectId}`,
                );
                return response.data;
            } catch (error) {
                return thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const offboardFutureOnboardedDeveloper =
    createAsyncThunk(
        'project/offboardFutureOnboardedDeveloper',
        async (data, thunkAPI) => {
            try {
                let response = await api.delete(
                    `api/v2/projects/deleteFutureOnboardings?scheduleId=${data.scheduleId}`,
                );
                return response.data;
            } catch (error) {
                return thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const getDeveloper = createAsyncThunk(
    'project/getDeveloper',
    async (id, thunkAPI) => {
        try {
            let response = await api.get(
                `api/v2/projects/getDevelopers?projectId=${id}`,
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response.data,
            );
        }
    },
);

export const updateBoarding = createAsyncThunk(
    'project/updateBoarding',
    async (data, thunkAPI) => {
        try {
            let response = await api.put(
                `api/v2/projects/updateboarding`,
                data,
            );
            return response.data;
        } catch (error) {
            thunkAPI.rejectWithValue(
                error.response.data,
            );
        }
    },
);

export const getOffboardedDevelopers =
    createAsyncThunk(
        'project/getOffboardedDevelopers',
        async (data, thunkAPI) => {
            try {
                let response = await api.get(
                    `api/v2/projects/getOffboardedDevelopers?projectId=${data.projectId}`,
                );
                return response.data;
            } catch (error) {
                thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const getOnboardedDevelopers =
    createAsyncThunk(
        'project/getOnboardedDevelopers',
        async (data, thunkAPI) => {
            try {
                let response = await api.get(
                    `api/v2/projects/getOnboardedDevelopers?projectId=${data.projectId}`,
                );
                return response.data;
            } catch (error) {
                thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

const ProjectsSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setFilterParameters(state, action) {
            state.searchFilters = action.payload;
        },
        setProjectsData(state, action) {
            state.projectsData = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getProjects.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.projects =
                    action?.payload?.data;
                state.error = '';
                state.totalCount =
                    action?.payload?.count;
            },
        );
        builder.addCase(
            getProjects.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            getProjects.rejected,
            (state, action) => {
                state.isLoading = false;
                state.projects = [];
                state.error =
                    action?.payload?.message;
            },
        );
        builder.addCase(
            getOffboardedDevelopers.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.offboardedDevelopers =
                    action?.payload?.data;
                state.error = '';
            },
        );
        builder.addCase(
            getOffboardedDevelopers.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            getOffboardedDevelopers.rejected,
            (state, action) => {
                state.isLoading = false;
                state.offboardedDevelopers = [];
                state.error =
                    action?.payload?.message;
            },
        );
        builder.addCase(
            getOnboardedDevelopers.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.onboardedDevelopers =
                    action?.payload?.data;
                state.error = '';
            },
        );
        builder.addCase(
            getOnboardedDevelopers.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            getOnboardedDevelopers.rejected,
            (state, action) => {
                state.isLoading = false;
                state.onboardedDevelopers = [];
                state.error =
                    action?.payload?.message;
            },
        );
        builder.addCase(
            getDeveloper.fulfilled,
            (state, action) => {
                state.isLoading1 = false;
                state.developerData =
                    action?.payload?.data?.developers;
                state.error = '';
            },
        );
        builder.addCase(
            getDeveloper.pending,
            (state, action) => {
                state.isLoading1 = true;
            },
        );
        builder.addCase(
            getDeveloper.rejected,
            (state, action) => {
                state.isLoading1 = false;
                state.developerData = [];
                state.error =
                    action?.payload?.message;
            },
        );
        builder.addCase(
            getProjectRemark.fulfilled,
            (state, action) => {
                state.displayRemarksLoading = false;
                state.projectRemarks =
                    action?.payload?.data?.remarks;
                state.error = '';
            },
        );
        builder.addCase(
            getProjectRemark.pending,
            (state, action) => {
                state.displayRemarksLoading = true;
            },
        );
        builder.addCase(
            getProjectRemark.rejected,
            (state, action) => {
                state.displayRemarksLoading = false;
                state.projectRemarks = [];
                state.error =
                    action?.payload?.message;
            },
        );
        builder.addCase(
            addProjectRemark.rejected,
            (state, action) => {
                state.error =
                    action?.payload?.message;
            },
        );
        builder.addCase(
            getStatusChangeLogs.fulfilled,
            (state, action) => {
                state.statusChangeLogs =
                    action?.payload?.data;
                state.error = '';
                state.isLoading1 = false;
            },
        );
        builder.addCase(
            getStatusChangeLogs.pending,
            (state, action) => {
                state.isLoading1 = true;
            },
        );
        builder.addCase(
            getStatusChangeLogs.rejected,
            (state, action) => {
                state.statusChangeLogs = [];
                state.error =
                    action?.payload?.message;
                state.isLoading1 = false;
            },
        );
        builder.addCase(
            getFutureOnboardingsLogs.fulfilled,
            (state, action) => {
                state.futureOnboardingsLogs =
                    action?.payload?.data;
                state.error = '';
                state.isLoading1 = false;
            },
        );
        builder.addCase(
            getFutureOnboardingsLogs.pending,
            (state, action) => {
                state.isLoading1 = true;
            },
        );
        builder.addCase(
            getFutureOnboardingsLogs.rejected,
            (state, action) => {
                state.futureOnboardingsLogs = [];
                state.error =
                    action?.payload?.message;
                state.isLoading1 = false;
            },
        );
        builder.addCase(
            searchProject.rejected,
            (state, action) => {
                state.isLoading = false;
                state.projects = [];
                state.error =
                    action?.error?.message;
            },
        );
        builder.addCase(
            searchProject.pending,
            (state, action) => {
                state.isLoading = true;
            },
        );
        builder.addCase(
            searchProject.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.projects =
                    action?.payload?.data;
                state.error = '';
                state.totalCount =
                    action?.payload?.count;
            },
        );
        builder.addCase(
            removeDeveloper.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.error = '';
            },
        );
        builder.addCase(
            removeDeveloper.rejected,
            (state, action) => {
                state.isLoading = false;
                state.error =
                    action.error.message;
            },
        );
    },
});

export const {
    setFilterParameters,
    setCurrentPage,
    setProjectsData,
} = ProjectsSlice.actions;
export default ProjectsSlice.reducer;
